import React from "react"
import HeroForm from "./heroForm"
import HeroImage from "./heroImage"
import heroStyles from "./hero.module.scss"
import heroImg from "../../images/docHero.png"
import { useStaticQuery, graphql } from "gatsby"

export default function Hero() {
  let data = useStaticQuery(graphql`
    query GettingImgHero {
      allAirtable(filter: {table: {eq: "Home"}, data: {section: {eq: "HeroForm"}, Name: {eq: "img"}}}) {
        nodes {
          data {
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
            section
          }
        }
      }
    }`)
    

  return (
    <div className={heroStyles.container}>
      <HeroImage img={data.allAirtable.nodes[0].data.img[0].thumbnails.full.url} />
      <HeroForm />
    </div>
  )
}
