import React from "react"
import heroFormStyles from "./heroForm.module.scss"
import ContactForm from "./contactForm"
import { useStaticQuery, graphql } from "gatsby"

export default function HeroForm() {
  let data = useStaticQuery(graphql`
    query MyQuery {
      allAirtable(filter: { data: { section: { eq: "HeroForm" }, data: {} } }) {
        nodes {
          table
          data {
            Name
            data
          }
        }
      }
    }
  `)

  let startDate = data.allAirtable.nodes.filter(
    node => node.data.Name === "InicioDeClases"
  )
  let dataDates = data.allAirtable.nodes.filter(
    node => node.data.Name === "OpcionesDeFechas"
  )
  let dataPlaces = data.allAirtable.nodes.filter(
    node => node.data.Name === "Lugares"
  )

  return (
    <div className={heroFormStyles.container}>
      <div className={heroFormStyles.formContainer}>
        <h1 className={heroFormStyles.title}>
          <strong>
            Cursos de preparación para el examen de admisión a la universidad
          </strong>
        </h1>
        <h2 className={heroFormStyles.subtitle}>
          Inicio a Clases <span>{startDate[0].data.data}</span>
        </h2>
        <ContactForm
          startDate={startDate}
          openCourses={dataDates}
          places={dataPlaces}
        />
      </div>
    </div>
  )
}
