import React from "react"
import VisionAndMision from "./visionAndMision"
import VisionAndMisionStyle from "./visionAndMisionMain.module.scss"

export default function VisionAndMisionMain() {
  let visionAndMision = [
    {
      id: 1,
      title: "Misión",
      text:
        "Ofrecer una formación educativa, a través de un servicio de calidad y profesionalismo, permitiendo a nuestros alumnos el acceso a la universidad logrando con ello la realización de sus proyectos de vida.",
    },
    {
      id: 2,
      title: "Visión",
      text:
        "Ser una empresa líder en asesorías educativas, a través de un servicio educativo de calidad con un gran compromiso social pleno de valores y principios.",
    },
  ]

  return (
    <div className={VisionAndMisionStyle.container}>
      {visionAndMision.map(foo => {
        return (
          <VisionAndMision key={foo.id} title={foo.title} text={foo.text} />
        )
      })}
    </div>
  )
}
