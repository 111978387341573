import React from "react"

import VisionAndMisionStyles from "./visionAndMision.module.scss"

export default function VisionAndMision(props) {
  return (
    <div>
      <h2 className={VisionAndMisionStyles.title}>{props.title}</h2>
      <div className={VisionAndMisionStyles.textContainer}>
        <p className={VisionAndMisionStyles.text}>{props.text}</p>
      </div>
    </div>
  )
}
