import React from "react"
import MoreInfoStyles from "./moreInfo.module.scss"

export default function MoreInfo() {
  return (
    <div>
      <div className={MoreInfoStyles.container}>
        <h1 className={MoreInfoStyles.title}>¿Necesitas más información?</h1>
      </div>
    </div>
  )
}
