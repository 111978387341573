import React from "react"
import blogStyles from "./blog.module.scss"
import BlogPost from "./blogPost"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "@brainhubeu/react-carousel"

export default function Blog(props) {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            postImage {
              id
              file {
                url
                fileName
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <div className={blogStyles.container}>
        <div className={blogStyles.grayArea}>
          <div className={blogStyles.titleContainer}>
            <h2 className={blogStyles.title}>
              Futuro universitario <strong>no dejes pasar el tiempo… </strong>
            </h2>
          </div>
        </div>
      </div>
      <div className={blogStyles.postsContainer}>
        <div className={blogStyles.browserView}>
          <div className={blogStyles.gridContainer}>
            {data.allContentfulBlogPost.edges.map(edge => {
              return (
                <BlogPost
                  title={edge.node.title}
                  url={edge.node.slug}
                  img={edge.node.postImage.file.url}
                  alt={edge.node.postImage.file.fileName}
                />
              )
            })}
          </div>
        </div>
        <div className={blogStyles.mobileView}>
          {/* <Carousel>
                        <div className={blogStyles.gridContainer}> 
                               <BlogPost {...posts[0]}/>
                               <BlogPost {...posts[1]}/>
                        </div>
                        <div className={blogStyles.gridContainer}> 
                                <BlogPost {...posts[2]}/>
                                <BlogPost {...posts[3]}/>
                        </div>

                    </Carousel> */}
        </div>
      </div>
    </div>
  )
}
