import React from "react"
import singleReviewStyles from "./singleReview.module.scss"

export default function singleReview(props) {
  return (
    <div className={singleReviewStyles.container}>
      <div className={singleReviewStyles.containerReview}>
        <div className={singleReviewStyles.imgContainer}>
          <img
            className={singleReviewStyles.img}
            src={props.img}
            alt="reviewPhoto"
          />
        </div>
        <div className={singleReview.nameContainer}>
          <p className={singleReviewStyles.name}>{props.name}</p>
        </div>
        <div className={singleReviewStyles.score}>{props.score}</div>
      </div>
    </div>
  )
}
