import React from "react"
import IconAndDescriptionStyles from "./iconAndDescription.module.scss"

export default function IconAndDescription(props) {
  return (
    <div className={IconAndDescriptionStyles.container}>
      <div className={IconAndDescriptionStyles.imgContainer}>
        <img
          className={IconAndDescriptionStyles.Img}
          src={props.img}
          alt="Icono"
        />
      </div>
      <div className={IconAndDescriptionStyles.descriptioContainer}>
        <p className={IconAndDescriptionStyles.Description}>
          {" "}
          {props.description}{" "}
        </p>
      </div>
    </div>
  )
}
