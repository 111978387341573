import React from "react"
import IconAndDescription from "./IconAndDescription"
import IconAndDescriptionStyles from "./iconAndDescriptionBanner.module.scss"
import img1 from "../../images/30.png"
import img2 from "../../images/3.png"
import img3 from "../../images/4.png"
import img4 from "../../images/5.png"

export default function IconsMain() {
  let iconsAndDescriptions = [
    {
      id: 212,
      img: img1,
      description: [
        "La  ",
        <strong>mejor inversión</strong>,
        " que puedas hacer",
      ],
    },
    {
      id: 2324,
      img: img2,
      description: [
        "El tiempo justo para estar ",
        <strong> 100% preparado </strong>,
      ],
    },
    {
      id: 324,
      img: img3,
      description: ["Cursos", <strong> completamente presenciales </strong>],
    },
    {
      id: 498,
      img: img4,
      description: <strong> Streaming en vivo (Clases de Repaso) </strong>,
    },
  ]

  return (
    <div className={IconAndDescriptionStyles.containerBanner}>
      {iconsAndDescriptions.map(foo => {
        return (
          <IconAndDescription
            key={foo.id}
            img={foo.img}
            description={foo.description}
          />
        )
      })}
    </div>
  )
}
