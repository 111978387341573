import React from "react"
import Carousel1 from "./Carousel1.module.scss"
import icon1 from "../../images/31.png"
import icon2 from "../../images/32.png"
import icon3 from "../../images/33.png"
import icon4 from "../../images/34.png"

export default function Carousel() {
  let icons = [icon1, icon2, icon3, icon4]

  return (
    <div className={Carousel1.container}>
      <div></div>
      {icons.map(icon => {
        return <img className={Carousel1.img} src={icon} alt={icon} />
      })}
      <div></div>
    </div>
  )
}
