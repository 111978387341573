import React from "react"
import ValuesStyles from "./values.module.scss"
import Carousel1 from "./iconCarousel1"
import Carousel2 from "./iconCarousel2"
import MobilCarousel from "./mobileCarousel"

export default function Values() {
  return (
    <div>
      <div className={ValuesStyles.browserView}>
        <div className={ValuesStyles.container}>
          <h1 className={ValuesStyles.title}>Valores</h1>
          <p className={ValuesStyles.text}>
            Son códigos de conducta que deben de distinguir a un asesor
            educativo.
          </p>
          <Carousel1 />
          <Carousel2 />
        </div>
      </div>
      <div className={ValuesStyles.mobileView}>
        <div className={ValuesStyles.container}>
          <h1 className={ValuesStyles.title}>Valores</h1>
          <p className={ValuesStyles.text}>
            Son códigos de conducta que deben de distinguir a un asesor
            educativo.
          </p>
          <div style={{ height: "200px" }}>
            <MobilCarousel />
          </div>
        </div>
      </div>
    </div>
  )
}
