import React, { useState, useEffect } from "react"
import reviewStyles from "./review.module.scss"
import SingleReview from "./singleReview"
import Carousel from "@brainhubeu/react-carousel"
import { useStaticQuery, graphql } from "gatsby"

export default function Review() {
  const [render, setRender] = useState(true)
  let data = useStaticQuery(graphql`
    query IndexReviews {
      allAirtable(filter: { data: { section: { eq: "Alumnos" }, data: {} } }) {
        nodes {
          table
          data {
            Name
            data
            data2
            data3
            data4
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let reviews = []
  data.allAirtable.nodes.map(node => {
    reviews.push({
      img: node.data.img[0].thumbnails.full.url,
      name: [
        node.data.data,
        <br />,
        <strong>{node.data.data2}</strong>,
        <br />,
        node.data.data3,
      ],
      score: node.data.data4,
    })

    return null
  })

  useEffect(() => {
    if (reviews.length === 0) {
      setRender(false)
    }
  })

  return (
    <div>
      {render ? (
        <div className={reviewStyles.container}>
          <div className={reviewStyles.containerText}>
            <div className={reviewStyles.containerTitle}>
              <h1 className={reviewStyles.title}>
                Experiencia de nuestros alumnos
              </h1>
            </div>
          </div>
          <div className={reviewStyles.browserView}>
            <div className={reviewStyles.containerReviews}>
              {reviews.map(review => {
                return <SingleReview {...review} />
              })}
            </div>
          </div>
          <div className={reviewStyles.mobileView}>
            <div className={reviewStyles.containerReviews}>
              <Carousel infinite animationSpeed={1500} autoPlay={3000}>
                {reviews.map(review => {
                  return <SingleReview {...review} />
                })}
              </Carousel>
            </div>
          </div>
          <div className={reviewStyles.emty}></div>
        </div>
      ) : (
        <div style={{ display: "none" }} />
      )}
    </div>
  )
}
