import React from "react"
import IconsMain from "../components/home/iconsMain"
import VisionAndMisionMain from "../components/home/visionAndMisionMain"
import WhoWeAreMain from "../components/home/whoWeAreMain"
import Values from "../components/home/values"
import MoreInfo from "../components/home/moreInfo"
import Locations from "../components/home/locations"
import generalStyles from "../components/general.module.scss"
import Blog from "../components/home/blog"
import Review from "../components/home/review"
import Convocatoria from "../components/home/convocatoria"
import Hero from "../components/home/hero"
import HeroImage1 from "../images/hero2.png"
import seoImage from "../images/uniacces.png"
import SEO from "../components/seo"
import Layout from '../components/layout/layout'

export default function Index() {
  return (
    <Layout style={{overflow:"hidden"}}>
      <SEO
        title="Home"
        description="Cursos presenciales de preparación para el examen de admisión"
        image={`https://uniacces.com${seoImage}`}
        url="/"
      />
      <Hero />
      <Blog />
      <IconsMain />
      <VisionAndMisionMain />
      <WhoWeAreMain
        HeroImage={HeroImage1}
        text={
          "UNIACCES Es una institución educativa que ofrece programas de preparación para los exámenes de admisión a la universidad, contamos con una infinidad de herramientas apegadas a las nuevas formas de estudio de los futuros universitarios. Es una institución impulsada por el experimentado académico Dr. Erick Gastélum quien cuenta con más de 11 años de trayectoria en el ámbito educativo,preprarando a los aspirantes de diferentes carreras en la universidad, contando con miles de casos de éxito que hablan de la calidad y profesionalismo."
        }
      />
      <Values />
      <Review />
      <Convocatoria />
      <MoreInfo />
      <Locations />
    </Layout>
  )
}
