import React from "react"
import WhoWeAreStyles from "./whoWeAreMain.module.scss"
import Icon from "../../images/40.png"
import { useStaticQuery, graphql } from "gatsby"

export default function VisionAndMisionMain(props) {
  let data = useStaticQuery(graphql`
    query IndexDescription {
      allAirtable(
        filter: { data: { section: { eq: "QuienesSomos" }, data: {} } }
      ) {
        nodes {
          table
          data {
            Name
            data
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let description = data.allAirtable.nodes.filter(
    node => node.data.Name === "textoQuienesSomos"
  )
  let img = data.allAirtable.nodes.filter(node => node.data.Name === "img")

  let whoWeAre = {
    id: 1,
    img: img[0].data.img[0].thumbnails.full.url,
    icon: Icon,
    text: description[0].data.data,
  }

  return (
    <div className={WhoWeAreStyles.container}>
      <div className={WhoWeAreStyles.iconAndTextContainer}>
        <img className={WhoWeAreStyles.icon} src={whoWeAre.icon} alt="icon" />
        <p className={WhoWeAreStyles.description}>{whoWeAre.text}</p>
      </div>
      <div className={WhoWeAreStyles.heroImageContainer}>
        <img
          className={WhoWeAreStyles.heroImage}
          src={whoWeAre.img}
          alt="heroImg"
        />
      </div>
    </div>
  )
}
