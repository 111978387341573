import React from "react"
import heroImageStyles from "./heroimage.module.scss"

export default function HeroImage(props) {
  return (
    <div className={heroImageStyles.container}>
      <img className={heroImageStyles.img} src={props.img} alt="heroImage1" />
    </div>
  )
}
