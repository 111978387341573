import React from "react"
import MobilCarrouselStyle from "./mobileCarousel.module.scss"
import Img1 from "../../images/m_1.png"
import Img2 from "../../images/m_2.png"
import Img3 from "../../images/m_3.png"
import Img4 from "../../images/m_4.png"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

export default function MobileCarrousel(props) {
  let imgs = [
    {
      img: Img1,
      id: 111,
    },
    {
      img: Img2,
      id: 22,
    },
    {
      img: Img3,
      id: 33,
    },
    {
      img: Img4,
      id: 44,
    },
  ]
  return (
    <div>
      <Carousel infinite animationSpeed={1500} autoPlay={3000}>
        {imgs.map((img, index) => {
          return (
            <div className={MobilCarrouselStyle.imgContainer}>
              <img
                className={MobilCarrouselStyle.img}
                src={img.img}
                alt="img"
              />{" "}
            </div>
          )
        })}
      </Carousel>
    </div>
  )
}
