import React from "react"
import Carousel2Style from "./Carousel2.module.scss"

export default function Carousel2() {
  let icons = [
    "Respeto",
    "Responsabilidad",
    "Tolerancia",
    "Dignidad",
    "Equidad",
  ]

  return (
    <div className={Carousel2Style.container}>
      <div></div>
      {icons.map(icon => {
        return <button className={Carousel2Style.btn}>{icon}</button>
      })}
      <div></div>
    </div>
  )
}
