import React, { useState } from "react"
import formStyles from "./form.module.scss"

export default function ContactForm(props) {
  const [ciclo, setCiclo] = useState("")
  const [name, setName] = useState("")
  const [lname, setLname] = useState("")
  const [residencia, setResidencia] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [focus, setFocus] = useState(false)
  const [submiting, setSubmiting] = useState(false)

  function handleSubmit(e) {
    e.preventDefault()
    setSubmiting("submiting")
    const data = {
      ciclo: ciclo,
      curso: "Solicitud de Informes",
      name: name,
      state: residencia,
      email: email,
      phone: phone,
    }
    fetch("https://hook.integromat.com/ffo4vjitcksu1byzh7y3ublf2xn0w2nc", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(response => {
      if (response.ok) {
        setSubmiting(true)
      } else setSubmiting("error")
    })
  }
  function handleFocus() {
    setFocus(true)
  }
  function handleBlur() {
    setFocus(false)
  }

  if (submiting === false) {
    return (
      <>
        <form className={formStyles.form}>
          <label className={formStyles.label}>Ciclo de Ingreso</label>
          <select
            onChange={e => setCiclo(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={[
              formStyles.selectInput,
              focus ? formStyles.focusedInput : formStyles.unfocusedInput,
            ].join(" ")}
          >
            <option disabled selected>
              {" "}
              -- Selecciona una opción --{" "}
            </option>
            {props.openCourses.map((course, index) => {
              return <option value={index}>{course.data.data}</option>
            })}
          </select>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            name="name"
            className={formStyles.input}
            placeholder="Nombre"
            autoComplete="given-name"
            required
          />
          <input
            type="text"
            value={lname}
            onChange={e => setLname(e.target.value)}
            name="lname"
            className={formStyles.input}
            placeholder="Apellidos"
            autoComplete="family-name"
            required
          />
          <label className={formStyles.label}>Lugar de Residencia</label>
          <select
            onChange={e => setResidencia(e.target.value)}
            value={residencia}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={[
              formStyles.selectInput,
              focus ? formStyles.focusedInput : formStyles.unfocusedInput,
            ].join(" ")}
          >
            <option disabled selected>
              {" "}
              -- Selecciona una opción --{" "}
            </option>
            {props.places.map((course, index) => {
              return (
                <option value={course.data.data}>{course.data.data}</option>
              )
            })}
          </select>
          <input
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
            className={formStyles.input}
            placeholder="Email"
            autoComplete="email"
            required
          />
          <input
            type="tel"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            name="email"
            className={formStyles.input}
            placeholder="Celular"
            autoComplete="tel"
            required
          />
          <div className={formStyles.checkContainer}>
            <input type="checkbox" className={formStyles.checkboxInput} />
            <p className={formStyles.checkDescription}>
              Tu información está segura y es confidencial. Al hacer clic
              confirmas estar de acuerdo con nuestro Aviso de Privacidad.
            </p>
          </div>
          <button
            type="submit"
            onClick={handleSubmit}
            className={formStyles.submitButton}
          >
            Solicitar Informes
          </button>
        </form>
      </>
    )
  } else if (submiting === "submiting") {
    return (
      <div className={formStyles.form}>
        <p className={formStyles.label}>Tus datos están siendo enviados...</p>
      </div>
    )
  } else if (submiting) {
    return (
      <div className={formStyles.form}>
        <p className={formStyles.label}>
          Gracias, tus datos datos han sido recibidos
        </p>
      </div>
    )
  } else if (submiting === "error") {
    return (
      <div className={formStyles.form}>
        <p className={formStyles.label}>
          Al parecer hubo un error {name}. Te invitamos a comunicarte por medio
          de messenger en el boton de la esquina inferior derecha
        </p>
      </div>
    )
  }
}
