import React from "react"
import convocatoriaStyles from "./convocatoria.module.scss"

import logo from "../../images/logoSinFondo2.png"

import { useStaticQuery, graphql } from "gatsby"

export default function Review() {
  let data = useStaticQuery(graphql`
    query IndexConvocatoria {
      allAirtable(
        filter: { data: { section: { eq: "Convocatoria" }, data: {} } }
      ) {
        nodes {
          table
          data {
            Name
            data
            data2
            data3
            data4
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let img = data.allAirtable.nodes.filter(node => node.data.Name === "img")
  let text = data.allAirtable.nodes.filter(node => node.data.Name === "parrafo")
  let url = data.allAirtable.nodes.filter(node => node.data.Name === "url")
  let title = data.allAirtable.nodes.filter(node => node.data.Name === "titulo")

  let convocatoria = {
    title: title[0].data.data,
    text: text[0].data.data,
    url: url[0].data.data,
    img: img[0].data.img[0].thumbnails.full.url,
  }

  console.log("IMAGGEENNN")
  console.log(convocatoria.img)
  return (
    <div
      className={convocatoriaStyles.container}
      style={{ backgroundImage: `url(${convocatoria.img})` }}
    >
      <div className={convocatoriaStyles.layer}>
        <div className={convocatoriaStyles.grid}>
          <div className={convocatoriaStyles.imgContainer}>
            <img className={convocatoriaStyles.img} src={logo} alt="logo" />
          </div>
          <div className={convocatoriaStyles.descriptionContainer}>
            <h1 className={convocatoriaStyles.title}>{convocatoria.title}</h1>
            <p className={convocatoriaStyles.text}>{convocatoria.text}</p>
            <button className={convocatoriaStyles.btn}>Seguir Leyendo</button>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
